import { GridSortItem } from "@mui/x-data-grid";
import dayjs, { Dayjs } from "dayjs";

const serverUrl = "https://casemanagement.floridahealthcareattorney.com:12345";

export enum QueryStatus {
	IDLE,
	LOADING,
	SUCCESS,
	FAILURE,
}

export enum SessionStatus {
	LOGGED_OUT,
	LOGGED_IN,
	EXPIRED,
}

export interface Detail {
	_id: string;
	date: string;
	assignee: string;
	description: string;
	isComplete?: boolean;
}

export interface Exemption {
	_id: string;
	application: string;
	status: string;
	submitted: string;
}

export interface LayoutField {
	label: string;
	type: "text" | "choice" | "email" | "phone" | "subform";
	choices?: string[];
	template?: LayoutField[];
	minimum?: number;
}

export interface SessionState {
	status: SessionStatus;
	token?: string;
}

export interface LayoutSettings {
	[id: string]: { overrides?: { [id: string]: string }; template: (LayoutField | null)[] };
}

export interface SettingsObject {
	_id?: string;
	caseTypes: string[];
	attorneyList: string[];
	accountTypes: string[];
	drmOtherTypes: string[];
	staffList: string[];
	statusTypes: string[];
	layoutByCaseType: LayoutSettings;
	exemptionApplicationTypes: string[];
	exemptionStatusTypes: string[];
}

export interface CommonData {
	caseType: string;
	attorney: string;
	accountType: string;
	DRMOther: string;
	staff: string;
	caseOpenDate: Dayjs | null;
	lastUpdate: Dayjs | null;
	status: string;
	caseNumber: string;
}

function wrapSignature(content: object, sessionState: SessionState) {
	return JSON.stringify({
		content,
		token: sessionState.token,
	});
}

export async function getData(query: object, sessionState: SessionState, setSessionState: (arg0: SessionState) => void) {
	try {
		const content = {
			action: "get-case-list",
			query,
		};
		const res = await (
			await fetch(serverUrl, {
				method: "post",
				body: wrapSignature(content, sessionState),
			})
		).json();
		if (res?.status === "Failure") {
			throw res?.reason;
		}
		return res;
	} catch (e) {
		if (e === "Expired Token") {
			setSessionState({
				status: SessionStatus.EXPIRED,
			});
		} else {
			throw e;
		}
	}
}

export async function getDeadlines(query: object, sessionState: SessionState, setSessionState: (arg0: SessionState) => void) {
	try {
		const content = {
			action: "get-deadlines",
			query,
		};
		const res = await (
			await fetch(serverUrl, {
				method: "post",
				body: wrapSignature(content, sessionState),
			})
		).json();
		if (res?.status === "Failure") {
			throw res?.reason;
		}
		return res;
	} catch (e) {
		if (e === "Expired Token") {
			setSessionState({
				status: SessionStatus.EXPIRED,
			});
		} else {
			throw e;
		}
	}
}

export async function getNotes(query: object, sessionState: SessionState, setSessionState: (arg0: SessionState) => void) {
	try {
		const content = {
			action: "get-notes",
			query,
		};
		const res = await (
			await fetch(serverUrl, {
				method: "post",
				body: wrapSignature(content, sessionState),
			})
		).json();
		if (res?.status === "Failure") {
			throw res?.reason;
		}
		return res;
	} catch (e) {
		if (e === "Expired Token") {
			setSessionState({
				status: SessionStatus.EXPIRED,
			});
		} else {
			throw e;
		}
	}
}

export async function getExemptions(query: object, sessionState: SessionState, setSessionState: (arg0: SessionState) => void) {
	try {
		const content = {
			action: "get-exemptions",
			query,
		};
		const res = await (
			await fetch(serverUrl, {
				method: "post",
				body: wrapSignature(content, sessionState),
			})
		).json();
		if (res?.status === "Failure") {
			throw res?.reason;
		}
		return res;
	} catch (e) {
		if (e === "Expired Token") {
			setSessionState({
				status: SessionStatus.EXPIRED,
			});
		} else {
			throw e;
		}
	}
}

export async function getDetails(query: object, sessionState: SessionState, setSessionState: (arg0: SessionState) => void) {
	try {
		const content = {
			action: "get-details",
			query,
		};
		const res = await (
			await fetch(serverUrl, {
				method: "post",
				body: wrapSignature(content, sessionState),
			})
		).json();
		if (res?.status === "Failure") {
			throw res?.reason;
		}
		return res;
	} catch (e) {
		if (e === "Expired Token") {
			setSessionState({
				status: SessionStatus.EXPIRED,
			});
		} else {
			throw e;
		}
	}
}

export async function getCase(id: string, sessionState: SessionState, setSessionState: (arg0: SessionState) => void) {
	try {
		const content = {
			action: "get-case",
			id,
		};
		const res = await (
			await fetch(serverUrl, {
				method: "post",
				body: wrapSignature(content, sessionState),
			})
		).json();
		if (res?.status === "Failure") {
			throw res?.reason;
		}
		return res;
	} catch (e) {
		if (e === "Expired Token") {
			setSessionState({
				status: SessionStatus.EXPIRED,
			});
		} else {
			throw e;
		}
	}
}

export async function getSettings(sessionState: SessionState, setSessionState: (arg0: SessionState) => void) {
	try {
		const content = {
			action: "get-settings",
		};
		const res = await (
			await fetch(serverUrl, {
				method: "post",
				body: wrapSignature(content, sessionState),
			})
		).json();
		if (res?.status === "Failure") {
			throw res?.reason;
		}
		return res;
	} catch (e) {
		if (e === "Expired Token") {
			setSessionState({
				status: SessionStatus.EXPIRED,
			});
		} else {
			throw e;
		}
	}
}

export async function postAction(
	content: object,
	sessionState: SessionState,
	setSessionState: (arg0: SessionState) => void,
	onSuccess?: (arg0: { [id: string]: any }) => void,
	onFailure?: (e: any) => void
) {
	try {
		const res = await (
			await fetch(serverUrl, {
				method: "post",
				body: wrapSignature(content, sessionState),
			})
		).json();
		if (res?.status === "Failure") {
			throw res?.reason;
		}
		if (onSuccess) {
			onSuccess(res);
		}
	} catch (e) {
		if (e === "Expired Token") {
			setSessionState({
				status: SessionStatus.EXPIRED,
			});
		}

		if (onFailure) {
			onFailure(e);
		} else {
			throw e;
		}
	}
}

export function compareDates(a: Dayjs | null, b: Dayjs | null) {
	if (a?.isValid() && b?.isValid()) {
		return a?.toISOString() === b?.toISOString();
	}
	if (a === null && b === null) {
		return true;
	}
	return false;
}

export function sortCaseListData(sortModel: GridSortItem[], data: object[], deadlines: { [id: string]: string }) {
	let sortedData = [...data];
	for (let e of sortModel) {
		switch (e.field) {
			case "clientName":
				switch (e.sort) {
					case "asc":
						sortedData.sort((a: any, b: any) =>
							(a["Client Name$"] || a["Client Name$_0"] || "").localeCompare(b["Client Name$"] || b["Client Name$_0"] || "")
						);
						break;
					case "desc":
						sortedData.sort(
							(a: any, b: any) => -(a["Client Name$"] || a["Client Name$_0"] || "").localeCompare(b["Client Name$"] || b["Client Name$_0"] || "")
						);
						break;
				}
				break;
			case "attorney":
				switch (e.sort) {
					case "asc":
						sortedData.sort((a: any, b: any) => (a["attorney"] < b["attorney"] ? -1 : a["attorney"] === b["attorney"] ? 0 : 1));
						break;
					case "desc":
						sortedData.sort((a: any, b: any) => (a["attorney"] < b["attorney"] ? 1 : a["attorney"] === b["attorney"] ? 0 : -1));
						break;
				}
				break;
			case "caseStatus":
				switch (e.sort) {
					case "asc":
						sortedData.sort((a: any, b: any) => (a["status"] < b["status"] ? -1 : a["status"] === b["status"] ? 0 : 1));
						break;
					case "desc":
						sortedData.sort((a: any, b: any) => (a["status"] < b["status"] ? 1 : a["status"] === b["status"] ? 0 : -1));
						break;
				}
				break;
			case "caseType":
				switch (e.sort) {
					case "asc":
						sortedData.sort((a: any, b: any) => (a["caseType"] < b["caseType"] ? -1 : a["caseType"] === b["caseType"] ? 0 : 1));
						break;
					case "desc":
						sortedData.sort((a: any, b: any) => (a["caseType"] < b["caseType"] ? 1 : a["caseType"] === b["caseType"] ? 0 : -1));
						break;
				}
				break;
			case "staff":
				switch (e.sort) {
					case "asc":
						sortedData.sort((a: any, b: any) => (a["staff"] < b["staff"] ? -1 : a["staff"] === b["staff"] ? 0 : 1));
						break;
					case "desc":
						sortedData.sort((a: any, b: any) => (a["staff"] < b["staff"] ? 1 : a["staff"] === b["staff"] ? 0 : -1));
						break;
				}
				break;
			case "deadline":
				switch (e.sort) {
					case "asc":
						sortedData.sort((a: any, b: any) => {
							if (deadlines[a["_id"]] === undefined && deadlines[b["_id"]] === undefined) {
								return 0;
							}
							if (deadlines[a["_id"]] === undefined) {
								return -1;
							}
							if (deadlines[b["_id"]] === undefined) {
								return 1;
							}
							return dayjs(deadlines[b["_id"]]).diff(dayjs(deadlines[a["_id"]]));
						});
						break;
					case "desc":
						sortedData.sort((a: any, b: any) => {
							if (deadlines[a["_id"]] === undefined && deadlines[b["_id"]] === undefined) {
								return 0;
							}
							if (deadlines[a["_id"]] === undefined) {
								return 1;
							}
							if (deadlines[b["_id"]] === undefined) {
								return -1;
							}
							return -dayjs(deadlines[b["_id"]]).diff(dayjs(deadlines[a["_id"]]));
						});
						break;
				}
				break;
		}
	}
	return sortedData;
}
